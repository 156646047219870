import "./App.css";
import { Route, Routes } from "react-router";
import { Header } from "./components/Header";
import { Home } from "./components/Home";
import { Contact } from "./components/Contact";
import { Partner } from "./components/Partner";
import { DSGVO } from "./components/DSGVO";

function App() {
  return (
    <div className="app">
      <Header />
      <div id="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/dsgvo" element={<DSGVO />} />
        </Routes>
      </div>
      <Contact />
    </div>
  );
}

export default App;
