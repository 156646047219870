import { useLocation, useNavigate } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { useEffect, useState } from "react";
import "./Header.css";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showStartText, setShowStartText] = useState(true);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setShowStartText(true);
        break;
      default:
        setShowStartText(false);
    }
  }, [location.pathname]);

  return (
    <div className="header">
      <div className="intro">
        <h1 id="img-container">
          <a href={`https://wolke7bysandrafitz.de${location.pathname}`}>
            <picture>
              <source
                srcSet="/img/Wolke7_Logo/Logo_512.webp"
                type="image/webp"
                media="(max-width:600px)"
              />
              <source
                srcSet="/img/Wolke7_Logo/Logo_1024.webp"
                type="image/webp"
                media="(max-width:900px)"
              />
              <source
                srcSet="/img/Wolke7_Logo/Logo_2048.webp"
                type="image/webp"
                media="(min-width:900px)"
              />
              <source
                srcSet="/img/Wolke7_Logo/Logo.webp"
                type="image/webp"
                media="(min-width:1200px)"
              />
              <img
                src="/img/Wolke7_Logo/Logo.webp"
                id="img"
                srcSet="image/webp"
                alt="Trauung Braunschweig, Traurednerin"
              />
            </picture>
          </a>
        </h1>
      </div>

      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="m-auto" onSelect={(k) => k && navigate(k)}>
            <Nav.Link eventKey="/">HOME</Nav.Link>
            <Nav.Link eventKey="/partner">PARTNER</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {showStartText && (
        <div id="start-text">
          <div className="container">
            <div className="hero-grouping">
              <div id="hero">Dein Traumtag perfekt geplant!</div>
              <ul>
                <li>Individuell gestaltete Traureden</li>
                <li>Komplette Hochzeitsplanung von A - Z</li>
                <li>Individuelles Dekorationskonzept</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
