import "./Portfolio.css";

export const Portfolio = () => {
  return (
    <div id="portfolio">
      <div className="container">
        <h2>Leistungen</h2>

        <div id="text-section">
          <h4>
            <strong>
              <u>All-round Paket</u>
            </strong>
          </h4>
          <p>
            Dieses Paket lässt euch zurücklehnen. Ich helfe euch bei der
            Ideenfindung für ein passendes Hochzeitsmotto bis hin zum Tränen
            trocknen an eurer eigenen Hochzeit. Mit Hilfe eurer Wünsche helfe
            ich euch bei der Wahl der richtigen Location, des Caterings, der
            Musik, sowie alles was Kleidung und Hochzeitspflege betrifft. Alles
            für den schönsten Tag in eurem Leben. Abgerundet wird dieses Paket
            mit einer inklusiven Hochzeitsmappe zur Übersicht und Erinnerung.
          </p>

          <ul>
            <li>
              Ideensammlung & Anpassung einer Übersicht mit eurem gewünschten
              Budget
            </li>
            <li>Vorstellung eines individuellen Zeitplans bis zur Hochzeit</li>
            <li>
              Vorstellung von Lokalitäten, Dekorateuren und Caterern passend zu
              euren Vorstellungen
            </li>
            <li>
              Detaillierte Übersicht über den Hochzeitstag, die Dienstleister,
              das Programm an der Hochzeit
            </li>
          </ul>

          <h4>
            <strong>
              <u>Trauungspaket</u>
            </strong>
          </h4>
          <p>
            Dieses Paket begleitet euch bei der Planung eurer Zeremonie.
            Benötigt ihr Hilfe bei der Organisation eines Gottesdienstes oder
            Freien Trauung? Ich helfe und begleite bei der Organisation der
            Bürokratie sowie auch bei Wunsch als Freie Traurednerin.
          </p>

          <ul>
            <li>Kennenlerngespräch</li>
            <li>Ausführliches Interview im persönlichen Gespräch</li>
            <li>Ausarbeitung einer individuellen Freien Trauung</li>
            <li>Durchführung der Freien Trauung inkl. Equipment</li>
          </ul>

          <h4>
            <strong>
              <u>Dienstleistungspaket</u>
            </strong>
          </h4>
          <p>
            Ihr seid schon in vielen Bereichen mit eurer Planung fertig, euch
            fehlt aber noch ein bestimmter Dienstleister? Durch die
            Zusammenarbeit mehrerer Dienstleister kann ich euch helfen z.B. noch
            die fehlende Dekorateurin oder die passende Visagistin zu finden.
          </p>
        </div>
      </div>
    </div>
  );
};
