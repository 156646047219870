import "./DSGVO.css";

export const DSGVO = () => {
  return (
    <div id="dsgvo">
      <div id="text" className="container">
        <h1>
          DATENSCHUTZINFORMATIONEN FÜR KUNDEN/ANDERE VERTRAGSPARTNER UND
          INTERESSENTEN
        </h1>
        <h2>
          Informationen zum Datenschutz über unsere Verarbeitung von Kunden- und
          Interessentendaten nach Artikel 13, 14 und 21 der
          Datenschutzgrundverordnung (DSGVO)
        </h2>
        <h3>Wolke 7 by Sandra Fitz</h3>
        <p>
          Liebe Kundin, lieber Kunde, liebe/r Interessent/in, liebe/r
          Vertragspartner/in{" "}
        </p>
        <p>
          gemäß den Vorgaben der Art. 13, 14 und 21 der
          Datenschutz-Grundverordnung (DSGVO) informieren wir Sie hiermit über
          die Verarbeitung Ihrer personenbezogenen Daten sowie Ihre
          diesbezüglichen datenschutzrechtlichen Rechte. Welche Daten im
          Einzelnen verarbeitet und in welcher Weise genutzt werden, richtet
          sich maßgeblich nach den angefragten bzw. vereinbarten Leistungen. Um
          zu gewährleisten, dass Sie in vollem Umfang über die Verarbeitung
          Ihrer personenbezogenen Daten im Rahmen der Erfüllung eines Vertrags
          oder der Durchführung vorvertraglicher Maßnahmen informiert sind,
          nehmen Sie bitte nachstehende Information zu Kenntnis.
        </p>

        <h2>1. VERANTWORTLICHE STELLE IM SINNE DES DATENSCHUTZRECHTS</h2>
        <p>
          Wolke 7 by Sandra Fitz
          <br />
          Herzogin-Elisabeth-Straße 6c
          <br />
          38159 Vechelde
          <br />
          01575-0491628
          <br />
          info@wolke7bysandrafitz.de
          <br />
          www.wolke7bysandrafitz.de
          <br />
        </p>

        <h2>2. KONTAKTDATEN UNSERES DATENSCHUTZBEAUFTRAGTEN</h2>
        <p>
          Wolke 7 by Sandra Fitz
          <br />
          Herzogin-Elisabeth-Straße 6c
          <br />
          38159 Vechelde
          <br />
          01575-0491628
          <br />
          info@wolke7bysandrafitz.de
          <br />
          www.wolke7bysandrafitz.de
          <br />
        </p>

        <h2>3. ZWECKE UND RECHTSGRUNDLAGEN DER VERARBEITUNG</h2>
        <p>
          Wir verarbeiten Ihre personenbezogenen Daten im Einklang mit den
          Bestimmungen der europäischen Datenschutz-Grundverordnung (DSGVO) und
          dem Bundesdatenschutzgesetz (BDSG), sofern diese für eine
          Vertragsbegründung, -durchführung, -erfüllung sowie zur Durchführung
          vorvertraglicher Maßnahmen erforderlich sind. Soweit zur Anbahnung
          oder Durchführung eines Vertragsverhältnisses oder im Rahmen der
          Durchführung vorvertraglicher Maßnahmen personenbezogene Daten
          erforderlich sind, ist eine Verarbeitung gemäß Art. 6 Abs. 1 lit. b
          DSGVO rechtmäßig. Erteilen Sie uns eine ausdrückliche Einwilligung zur
          Verarbeitung von personenbezogenen Daten für bestimmte Zwecke (z. B.
          Weitergabe an Dritte, Auswertung für Marketingzwecke oder werbliche
          Ansprache per E-Mail), ist die Rechtmäßigkeit dieser Verarbeitung auf
          Basis Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO gegeben. Eine
          erteilte Einwilligung kann jederzeit mit Wirkung für die Zukunft
          widerrufen werden (s. Ziffer 9 dieser Datenschutzinformation). Sofern
          erforderlich und gesetzlich zulässig, verarbeiten wir Ihre Daten über
          die eigentlichen Vertragszwecke hinaus zur Erfüllung rechtlicher
          Verpflichtungen gemäß Art. 6 Abs. 1 lit. c DSGVO. Darüber hinaus
          erfolgt eine Verarbeitung ggf. zur Wahrung berechtigter Interessen von
          uns oder Dritten sowie zur Abwehr und Geltendmachung von
          Rechtsansprüchen gemäß Art. 6 Abs. 1 lit. f DSGVO. Gegebenenfalls
          werden wir Sie unter Angabe des berechtigten Interesses gesondert
          informieren, soweit dies gesetzlich vorgegeben ist.
        </p>

        <h2>4. KATEGORIEN PERSONENBEZOGENER DATEN</h2>
        <p>
          Wir verarbeiten nur solche Daten, die mit der Vertragsbegründung bzw.
          den vorvertraglichen Maßnahmen in Zusammenhang stehen. Dies können
          allgemeine Daten zu Ihrer Person bzw. Personen Ihres Unternehmens sein
          (Name, Anschrift, Kontaktdaten etc.) sowie ggf. weitere Daten, die Sie
          uns im Rahmen der Begründung des Vertrags übermitteln.
        </p>

        <h2>5. QUELLEN DER DATEN</h2>
        <p>
          Wir verarbeiten personenbezogene Daten, die wir im Rahmen der
          Kontaktaufnahme bzw. der Begründung eines Vertragsverhältnisses oder
          im Rahmen vorvertraglicher Maßnahmen von Ihnen erhalten bzw. die Sie
          über unser Kontaktformular auf unserer Internetseite angeben.
        </p>

        <h2>6. EMPFÄNGER DER DATEN</h2>
        <p>
          Wir geben Ihre personenbezogenen Daten innerhalb unseres Unternehmens
          ausschließlich an die Bereiche und Personen weiter, die diese Daten
          zur Erfüllung der vertraglichen und gesetzlichen Pflichten bzw. zur
          Umsetzung unseres berechtigten Interesses benötigen. Wir können Ihre
          personenbezogenen Daten an mit uns verbundene Unternehmen übermitteln,
          soweit dies im Rahmen der unter Ziffer 3 dieses
          Datenschutzinformationsblatts dargelegten Zwecke und Rechtsgrundlagen
          zulässig ist. Ihre personenbezogenen Daten werden in unserem Auftrag
          auf Basis von Auftragsverarbeitungsverträgen nach Art. 28 DSGVO
          verarbeitet. In diesen Fällen stellen wir sicher, dass die
          Verarbeitung von personenbezogenen Daten im Einklang mit den
          Bestimmungen der DSGVO erfolgt. Die Kategorien von Empfängern sind in
          diesem Fall Anbieter von Internetdienstanbieter sowie Anbieter von
          Kundenmanagementsystemen und -software. Eine Datenweitergabe an
          Empfänger außerhalb des Unternehmens erfolgt ansonsten nur, soweit
          gesetzliche Bestimmungen dies erlauben oder gebieten, die Weitergabe
          zur Abwicklung und somit zur Erfüllung des Vertrages oder, auf Ihren
          Antrag hin, zur Durchführung von vorvertraglichen Maßnahmen
          erforderlich ist, uns Ihre Einwilligung vorliegt oder wir zur
          Erteilung einer Auskunft befugt sind. Unter diesen Voraussetzungen
          können Empfänger personenbezogener Daten z. B. sein:
          <ul>
            <li>Externer Steuerberater</li>
            <li>
              Öffentliche Stellen und Institutionen (z. B. Staatsanwaltschaft,
              Polizei, Aufsichtsbehörden, Finanzamt) bei Vorliegen einer
              gesetzlichen oder behördlichen Verpflichtung,{" "}
            </li>
            <li>
              Empfänger, an die die Weitergabe zur Vertragsbegründung oder
              -erfüllung unmittelbar erforderlich ist, wie z. B. Veranstalter,
              Caterer und andere Dienstleister,{" "}
            </li>
            <li>
              Weitere Datenempfänger, für die Sie uns Ihre Einwilligung zur
              Datenübermittlung erteilt haben
            </li>
          </ul>
        </p>

        <h2>7. ÜBERMITTLUNG IN EIN DRITTLAND</h2>
        <p>
          Eine Übermittlung in ein Drittland ist nicht beabsichtigt. Eine
          Übermittlung von personenbezogenen Daten in Länder außerhalb des EWR
          (Europäischer Wirtschaftsraum) oder an eine internationale
          Organisation findet nur statt, soweit dies zur Abwicklung und somit
          zur Erfüllung des Vertrages oder, auf Ihren Antrag hin, zur
          Durchführung von vorvertraglichen Maßnahmen erforderlich ist, die
          Weitergabe gesetzlich vorgeschrieben ist oder Sie uns eine
          Einwilligung erteilt haben.
        </p>

        <h2>8. DAUER DER DATENSPEICHERUNG</h2>
        <p>
          Soweit erforderlich verarbeiten und speichern wir Ihre
          personenbezogenen Daten für die Dauer unserer Geschäftsbeziehung bzw.
          zur Erfüllung vertraglicher Zwecke. Dies umfasst u. a. auch die
          Anbahnung und die Abwicklung eines Vertrages. Darüber hinaus
          unterliegen wir verschiedenen Aufbewahrungs- und
          Dokumentationspflichten, die sich unter anderem aus dem
          Handelsgesetzbuch (HGB) und der Abgabenordnung (AO) ergeben. Die dort
          vorgeschriebenen Fristen zur Aufbewahrung bzw. Dokumentation betragen
          zwei bis zehn Jahre. Schließlich richtet sich die Speicherdauer auch
          nach den gesetzlichen Verjährungsfristen, die z. B. nach den §§ 195
          ff. des Bürgerlichen Gesetzbuches (BGB) in der Regel drei Jahre, in
          gewissen Fällen aber auch bis zu dreißig Jahre betragen können.
        </p>

        <h2>9. IHRE RECHTE</h2>
        <p>
          Jede betroffene Person hat das Recht auf Auskunft nach Art. 15 DSGVO,
          das Recht auf Berichtigung nach Art. 16 DSGVO, das Recht auf Löschung
          nach Art. 17 DSGVO, das Recht auf Einschränkung der Verarbeitung nach
          Art. 18 DSGVO, das Recht auf Mitteilung nach Art. 19 DSGVO sowie das
          Recht auf Datenübertragbarkeit nach Art. 20 DSGVO. Darüber hinaus
          besteht ein Beschwerderecht bei einer Datenschutzaufsichtsbehörde nach
          Art. 77 DSGVO, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer
          personenbezogenen Daten nicht rechtmäßig erfolgt. Das Beschwerderecht
          besteht unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs. Sofern die Verarbeitung von Daten auf
          Grundlage Ihrer Einwilligung erfolgt, sind Sie nach Art. 7 DSGVO
          berechtigt, die Einwilligung in die Verwendung Ihrer personenbezogenen
          Daten jederzeit zu widerrufen. Bitte beachten Sie, dass der Widerruf
          erst für die Zukunft wirkt. Verarbeitungen, die vor dem Widerruf
          erfolgt sind, sind davon nicht betroffen. Bitte beachten Sie zudem,
          dass wir bestimmte Daten für die Erfüllung gesetzlicher Vorgaben ggf.
          für einen bestimmten Zeitraum aufbewahren müssen (s. Ziffer 8 dieser
          Datenschutzinformation).
        </p>

        <h3>Widerspruchsrecht</h3>
        <p>
          Soweit die Verarbeitung Ihre personenbezogenen Daten nach Art. 6 Abs.
          1 lit. f DSGVO zur Wahrung berechtigter Interessen erfolgt, haben Sie
          gemäß Art. 21 DSGVO das Recht, aus Gründen, die sich aus Ihrer
          besonderen Situation ergeben, jederzeit Widerspruch gegen die
          Verarbeitung dieser Daten einzulegen. Wir verarbeiten diese
          personenbezogenen Daten dann nicht mehr, es sei denn, wir können
          zwingende schutzwürdige Gründe für die Verarbeitung nachweisen. Diese
          müssen Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
          Verarbeitung muss der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen dienen. In Einzelfällen verarbeiten wir Ihre
          personenbezogenen Daten, um Direktwerbung zu betreiben. Sie haben das
          Recht, jederzeit Widerspruch gegen die Verarbeitung zum Zwecke
          derartiger Werbung einzulegen. Dies gilt auch für das Profiling,
          soweit es mit dieser Direktwerbung in Verbindung steht. Widersprechen
          Sie der Verarbeitung zum Zwecke von Direktwerbung, werden wir Ihre
          personenbezogenen Daten nicht weiter für diese Zwecke verarbeiten. Zur
          Wahrung Ihrer Rechte können Sie sich unter den in Ziffer 1 angegebenen
          Kontaktdaten an uns wenden.
        </p>

        <h2>10. ERFORDERLICHKEIT DER BEREITSTELLUNG PERSONENBEZOGENER DATEN</h2>
        <p>
          Die Bereitstellung personenbezogener Daten für die Entscheidung über
          einen Vertragsabschluss, die Vertragserfüllung oder zur Durchführung
          vorvertraglicher Maßnahmen erfolgt freiwillig. Wir können eine
          Entscheidung im Rahmen vertraglicher Maßnahmen jedoch nur treffen,
          sofern Sie solche personenbezogenen Daten angeben, die für den
          Vertragsschluss, die Vertragserfüllung bzw. vorvertragliche Maßnahmen
          erforderlich sind.
        </p>

        <h2>11. AUTOMATISIERTE ENTSCHEIDUNGSFINDUNG</h2>
        <p>
          Zur Begründung, Erfüllung oder Durchführung der Geschäftsbeziehung
          sowie für vorvertragliche Maßnahmen nutzen wir grundsätzlich keine
          vollautomatisierte Entscheidungsfindung gemäß Art. 22 DSGVO. Sollten
          wir diese Verfahren in Einzelfällen einsetzen, werden wir Sie hierüber
          gesondert informieren bzw. Ihre Einwilligung einholen, sofern dies
          gesetzlich vorgegeben ist.
        </p>
      </div>
    </div>
  );
};
