import { Helmet } from "react-helmet";
import "./Partner.css";

export const Partner = () => {
  return (
    <div id="partner">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wolke 7 - Meine Partner</title>
        <link rel="canonical" href="https://wolke7bysandrafitz.de/partner" />
        <meta
          name="description"
          content="Hier findest du weitere Dienstleister Rund um das Thema Hochzeit."
        />
      </Helmet>
      <div id="partner-cards" className="container">
        <div className="card">
          <h2>ERMIX Music</h2>
          <p>
            Ihr DJ, Saxophonist und Sänger für Hochzeiten und weitere Events!{" "}
          </p>
          <a href="https://www.ermixmusic.de/" className="link">
            <img
              src="/img/link-logos/ermix.webp"
              className="link-img"
              id="hair-img"
              alt="ERMIX Music"
            />
          </a>
        </div>

        <div className="card">
          <h2>Aoun Photo</h2>
          <p>
            Authentische und emotionale Hochzeitsfotografie aus Braunschweig.{" "}
          </p>
          <a href="https://www.aounphoto.de/" className="link">
            <img
              src="/img/link-logos/aounphoto_512.webp"
              className="link-img"
              id="photo-img"
              alt="Authentische und emotionale Hochzeitsfotografie aus Braunschweig"
              loading="lazy"
            />
          </a>
        </div>

        <div className="card">
          <h2>Winzerli</h2>
          <p>
            Winzerli bietet Ihnen Service von A-Z für Ihre Veranstaltung. Ob
            Hochzeit, Geburtstag, Firmenfeier, Jubiläum, Konfirmation oder jeden
            anderen Anlass um etwas zu feiern. Wir bieten Ihnen von Gestaltung
            und Druck Ihrer Einladungskarten, über Gastgeschenke bis hin zum
            Catering und Service vor Ort alles was Sie brauchen um Ihre
            Veranstaltung zu perfektionieren.
          </p>
          <a href="https://www.winzerli.de/" className="link">
            <img
              src="/img/link-logos/Winzerli.webp"
              className="link-img"
              id="photo-img"
              alt="Winzerli Partner Link"
              loading="lazy"
            />
          </a>
        </div>

        <div className="card">
          <h2>Miriam Miller Cake</h2>
          <p>
            Hier findest du alles, was deinen Gaumen verwöhnt. Torten, Muffins
            und vieles mehr machen deine Hochzeit mit einem Blickfang perfekt.{" "}
          </p>
          <a href="https://www.mmcakeart.de/" className="link">
            <img
              src="/img/link-logos/cakes.png"
              className="link-img"
              id="cake-img"
              alt="Cookies and Cake"
              loading="lazy"
            />
          </a>
        </div>

        <div className="card">
          <h2>Make up & Hair bei Anna-Lena Deutsch</h2>
          <p>
            Anna verzaubert alle Bräute mit dem perfekten Make Up und einer
            tollen Frisur. Ebenfalls sind Mütter, Trauzeuginnen und Freundinnen
            herzlich willkommen.{" "}
          </p>
          <a
            href="https://www.instagram.com/makeup_by_anna.deutsch/"
            className="link"
          >
            <img
              src="/img/link-logos/hairandmakeup.svg"
              className="link-img"
              id="hair-img"
              alt="Logo Hair and Makeup"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
